import axios from 'axios'



const axiosinstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': "application/json", 
  }, 
});

export default axiosinstance;